import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/global/layout"
import Intro from "../components/collections/intro"
import FilterGrid from "../components/filter/filter-grid"
import Container from "../components/container/container"
import Button from "../components/button/button"

const CustomUrl = ({
  data: {
    datoCmsCustomUrl,
    allPortalWine,
    allDatoCmsRegionChild: { regionChildNodes },
  },
}) => {
  const { title, description, urlBuilder } = datoCmsCustomUrl

  const urlGrapes = urlBuilder.filter(
    (item) => item.internal.type === "DatoCmsGrapeUrl"
  )
  const urlTypes = urlBuilder.filter(
    (item) => item.internal.type === "DatoCmsWineTypeUrl"
  )
  const urlChildRegions = urlBuilder.filter(
    (item) => item.internal.type === "DatoCmsChildRegionUrl"
  )
  const urlParentRegion = urlBuilder.filter(
    (item) => item.internal.type === "DatoCmsParentRegionUrl"
  )
  const urlWinery = urlBuilder.filter(
    (item) => item.internal.type === "DatoCmsWineryUrl"
  )
  const urlPrice = urlBuilder.filter(
    (item) => item.internal.type === "DatoCmsPriceUrl"
  )

  const hasParentRegion = urlParentRegion.length > 0 ? true : false

  let childIds = []

  if (hasParentRegion) {
    const parentChildRegions = regionChildNodes.filter(
      (item) => item.parentRegion.id === urlParentRegion[0].link.id
    )
    childIds = parentChildRegions.map((item) => item.portalRegionId)
  }

  const allWines = allPortalWine.nodes

  const filterWines = () => {
    let arr = allWines.filter((wine) => {
      let result = false
      if (filterGrapes(wine)) {
        if (filterTypes(wine)) {
          if (filterParentRegion(wine)) {
            if (filterRegion(wine)) {
              if (filterWinery(wine)) {
                if (filterPrice(wine)) {
                  result = true
                }
              }
            }
          }
        }
      }
      return result
    })

    return arr
  }

  // Filter grapes if there is a grapeURL
  const filterGrapes = (wine) => {
    let res = false
    if (urlGrapes.length <= 0) {
      return true
    }
    const id = wine.grape[0].id
    if (id === urlGrapes[0].link.portalId) {
      res = true
    }
    return res
  }

  // Filter Categories if there is a grapeURL
  const filterTypes = (wine) => {
    let res = false
    if (urlTypes.length <= 0) {
      return true
    }
    if (wine.category === urlTypes[0].link.title) {
      res = true
    }
    return res
  }

  // Filter Winery if there is a winery URL
  const filterWinery = (wine) => {
    let res = false
    if (urlWinery.length <= 0) {
      return true
    }
    if (wine.winery.id.toString() === urlWinery[0].portalId.toString()) {
      res = true
    }
    return res
  }

  // Filter Child Regions if there is a child region URL
  const filterRegion = (wine) => {
    let res = false
    if (urlChildRegions.length <= 0) {
      return true
    }
    if (wine.winery.region_id === urlChildRegions[0].link.portalId) {
      res = true
    }
    return res
  }

  // Filter Parent Regions if there is a child region URL
  const filterParentRegion = (wine) => {
    let res = false
    if (!hasParentRegion) {
      return true
    }
    if (childIds.includes(wine.winery.region_id)) {
      res = true
    }
    return res
  }
  
  const filterPrice = (wine) => {
    let l = urlPrice.length
    if (l <= 0) {
      return true
    }
    let res = true
    for (let i=0; i<l; i++) {
      let p = urlPrice[i]
      if (p.minMax === 'min' && p.price > wine.price) {
        res = false
        break
      }
      if (p.minMax === 'max' && p.price < wine.price) {
        res = false
        break
      }
    }
    return res
  }

  const filteredWines = filterWines()

  // Load More functionality
  const pagesize = 20
  const [loadMore, setLoadMore] = useState(false)
  const [list, setList] = useState([...filteredWines.slice(0, pagesize)])
  const [hasMore, setHasMore] = useState(filteredWines.length > pagesize)
  useEffect(() => {
    if (loadMore && hasMore) {
      let currentLength = list.length
      let isMore = currentLength < filteredWines.length
      let nextResults = isMore
        ? filteredWines.slice(currentLength, currentLength + pagesize)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
      setHasMore(currentLength + nextResults.length < filteredWines.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMore])

  // detect touch screen
  const isBrowser = () => typeof window !== "undefined"
  const [isTouchScreen, setIsTouchScreen] = useState(false)
  const onTouch = () => {
    setIsTouchScreen(true)
    isBrowser && window.removeEventListener("touchstart", onTouch, false)
  }
  useEffect(() => {
    isBrowser && window.addEventListener("touchstart", onTouch, false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout seoTitle={`iHeartWine | ${title}`} seoDescription={description}>
      <div>
        <Intro title={title} description={description} />
        <Container gutters width="large">
          <FilterGrid
            products={list}
            isTouchScreen={isTouchScreen}
            isCellar={false}
          />
          {hasMore && (
            <Container width="large" gutters pt={0} pb={3} textAlign="center">
              <Button
                onClick={() => {
                  setLoadMore(true)
                }}
              >
                {"Load more"}
              </Button>
            </Container>
          )}
        </Container>
      </div>
    </Layout>
  )
}

export default CustomUrl

export const query = graphql`
  query CustomUrlQuery($id: String!) {
    datoCmsCustomUrl(originalId: { eq: $id }) {
      title
      originalId
      description
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      urlBuilder {
        ... on DatoCmsWineTypeUrl {
          id: originalId
          title
          slug
          internal {
            type
          }
          link {
            title
            color
          }
        }
        ... on DatoCmsGrapeUrl {
          id: originalId
          title
          slug
          internal {
            type
          }
          link: wineType {
            title
            portalId
          }
        }
        ... on DatoCmsParentRegionUrl {
          id: originalId
          title
          slug
          internal {
            type
          }
          link {
            title
            id: originalId
          }
        }
        ... on DatoCmsChildRegionUrl {
          id: originalId
          title
          slug
          internal {
            type
          }
          link {
            portalRegionId
            title
          }
        }
        ... on DatoCmsWineryUrl {
          id: originalId
          title
          slug
          portalId
          internal {
            type
          }
        }
        ... on DatoCmsPriceUrl {
          id: originalId
          title
          slug
          internal {
            type
          }
          price
          minMax
        }
      }
    }
    allPortalWine {
      nodes {
        category
        id
        name
        price
        rrp
        discounted_price
        image
        shiiping_message
        status
        food_pairing {
          id
          name
        }
        wine_type
        year
        wine_tags {
          id
          name
        }
        grape {
          name
          id
        }
        winery {
          name
          region_id
          minimum_wines_per_order
          id
          region {
            name
            id
          }
        }
      }
    }
    allDatoCmsRegionChild {
      regionChildNodes: nodes {
        id: originalId
        slug
        portalRegionId
        parentRegion {
          slug
          id: originalId
        }
      }
    }
  }
`
